import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home_Page from './components/home_page';

function App() {
  return (
    <Router> {/* Wrap your routes with Router */}
      <div>
        <Routes> 
          {/* Use `element` prop to render the component */}
          <Route path="/" element={<Home_Page />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
