import React, { useEffect, useState, useRef } from 'react';
import './css/home_page.css';  // Relative path to the CSS file
import Airtable from 'airtable';
import AOS from "aos";
import "aos/dist/aos.css";
function Home_Page() {

// alert box
// Alert state and functions
const [alertVisible, setAlertVisible] = useState(false);  // Track alert visibility
const [alertMessage, setAlertMessage] = useState('');

// Function to show the custom alert
const showAlert = (message) => {
  setAlertMessage(message);
  setAlertVisible(true);  // Display the alert
};

// Function to close the alert box
const closeAlert = () => {
  setAlertVisible(false);  // Hide the alert
};

// animations

useEffect(() => {
  AOS.init({
    duration: 800, // Animation duration in ms
    once: true, // Whether animation should happen only once
  });
}, []);


// scroll
const formRef = useRef(null); //ref for the form

const scrollToForm = () => {
  formRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Scroll smoothly to the form
};


// submission of form

  const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY; // Make sure to set this in your environment variables
  const base = new Airtable({ apiKey }).base('appjGc22Ul3ubCsGB'); // Replace with your Airtable Base ID
  const table = base('Contact_Sheet'); // Replace 'Sheet' with your table name
  const [agreeToPolicy, setAgreeToPolicy] = useState(false); // Checkbox state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setAgreeToPolicy(e.target.checked);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const { name, email, phone, message } = formData;
  
    // Validation logic with alert boxes
    if (!name || !email) {
      showAlert("Please fill out all required fields (Name and Email).");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email format validation
    if (!emailRegex.test(email)) {
      showAlert("Please enter a valid email address.");
      return;
    }
  
    const phoneRegex = /^\+?[1-9]\d{1,14}$/; // Phone number validation (E.164 format)
    if (phone && !phoneRegex.test(phone)) {
      showAlert("Please enter a valid phone number with country code (e.g., +1234567890).");
      return;
    }

    if (!agreeToPolicy) {
      showAlert("You must agree to our privacy policy before submitting.");
      return;
    }

  
    try {
      // Check if the email already exists in Airtable
      const records = await table
        .select({
          filterByFormula: `{Email} = "${email}"`, // Check for existing email
        })
        .firstPage();
  
      if (records.length > 0) {
        // Email already exists; append/update the record
        const recordId = records[0].id; // Get the existing record's ID
        const existingFields = records[0].fields;
  
        await table.update([
          {
            id: recordId,
            fields: {
              Name: name || existingFields.Name,
              'Phone Number': phone || existingFields['Phone Number'],
              Message: existingFields.Message
                ? `${existingFields.Message}\n${message || ''}`.trim()
                : message || null,
            },
          },
        ]);
  
        showAlert("We have received your information. Our team will be in touch with you soon!"); // Success alert
      } else {
        // Email does not exist; create a new record
        await table.create([
          {
            fields: {
              Name: name,
              Email: email,
              'Phone Number': phone || null,
              Message: message || null,
            },
          },
        ]);
  
        showAlert("We have received your message. Our team will be in touch with you soon!"); // Success alert
      }
  
      // Clear the form after successful submission
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      console.error("Error handling form submission with Airtable:", error);
      showAlert("An error occurred while submitting the form. Please try again.");
    }
  };

  // Website data
  const website_data = {
    header: {
      logo: "YourLogoHere",
      about_us: "About us",
      contact_us: "Contact us",
    },
    first_section: {
      heading1: "AI Chatbot for Websites:",
      heading2 : "Boost Your Customer Engagement & Sales",
      core_message: {
        text: "Swift Chatbot seamlessly integrates with your website, providing 24/7 automated customer support and instant responses for improved customer satisfaction.",
      }
    },
    testimonial_section: {
      heading: "Client Reviews – Success Stories with Swift Chatbot",
      testimonial_message: "Coming soon!" // Placeholder text for now
    },
    second_section: {
      heading: "Top Benefits of Swift Chatbot – Why AI Chatbots are the Future",
      core_message: {
        text: "Our AI-powered chatbot is designed to enhance customer service, save time on support, and automate sales for increased revenue."
      }
    },
    services_and_benefits: {
      heading: "AI Chatbot Features & Benefits for Businesses",
      benefits: [
        {
          heading: "24/7 AI Customer Support Automation",
          description: "Our chatbot is available 24/7 to assist your customers, reducing the need for human intervention and providing instant responses."
        },
        {
          heading: "Instant AI Chatbot Response Times",
          description: "With AI-powered technology, our chatbot provides immediate responses, enhancing user experience and customer satisfaction."
        },
        {
          heading: "Easy AI Chatbot Integration for Websites",
          description: "Swift Chatbot can be quickly integrated into any website with minimal setup, making it easy for businesses to get started."
        },
        {
          heading: "AI-Powered Personalized Customer Interaction",
          description: "The chatbot tailors conversations based on each customer's needs, providing personalized interactions and improving engagement."
        },
        {
          heading: "Save Money with AI Chatbot Automation",
          description: "By automating customer service tasks, businesses can save on staffing costs while improving efficiency."
        },
        {
          heading: "Boost Customer Engagement and Satisfaction",
          description: "Our AI chatbot ensures continuous engagement with customers, enhancing satisfaction and boosting brand loyalty."
        }
      ]
    },    
    call_to_action_section: {
      heading: "Integrate an AI Chatbot Into Your Website Today!",
      contact_form: {
        fields: [
          { label: "Name *", type: "text", placeholder: "Your Name" },
          { label: "Email *", type: "email", placeholder: "Your Email" },
          { label: "Phone (Country Code + Number)", type: "tel", placeholder: "Your Phone Number" },
          { label: "Message", type: "textarea", placeholder: "Your Message" }
        ],
        submit_button: {
          text: "Contact us",
          action: "/submit_form" // Submit form action
        }
      }
    },
    footer: {
      text:"© Swift Chatbot 2024. All Rights Reserved.",
      legal: {
        privacy_policy: "/privacy",
      }
    },
    seo: {
      title: "Swift Chatbot – AI Chatbots for Websites and Customer Support",
      meta_description: "Boost customer engagement and sales with SwiftChatbot. Get AI-powered, 24/7 customer support for your website. Start your free trial today!",
      keywords: [
        "AI chatbot for websites", 
        "AI customer service chatbot", 
        "automated customer support", 
        "instant chatbot response", 
        "AI chatbot for small businesses"
      ]
    }
  };
  useEffect(() => {
    // Update the page title
    document.title = website_data.seo.title;
  
    // Update meta tags if they exist
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');
  
    // Set the content attribute for description if the meta tag exists
    if (metaDescription) {
      metaDescription.setAttribute("content", website_data.seo.meta_description);
    }
  
    // Set the content attribute for keywords if the meta tag exists
    if (metaKeywords) {
      metaKeywords.setAttribute("content", website_data.seo.keywords.join(", "));
    }
  }, []);

 return (
    <div className='home-fullpage'>
       <div id="custom-alert" className={`alert-container ${alertVisible ? 'show' : ''}`}>
        <div className="alert-box">
          <span id="alert-message">{alertMessage}</span>
          <button className="close-btn" onClick={closeAlert}>OK</button>
        </div>
      </div>
       <img className='logo_header-top' alt="Logo" src="/media/logo_white2.png"/>
      <div className='gradient1'>
         {/* Header Section */}
         <div className='home-header-total'>
        <div className='home-header'>
        <a href="/about-us" className='about_header' target='_blank'>{website_data.header.about_us}</a>
       
      <button className='contact-button' onClick={scrollToForm}>{website_data.header.contact_us}</button>
      <a href="/privacy-policy" className='privacy-policy' target='_blank'>Privacy Policy</a>
    </div>
        
      <div className="visuals-header">
      <video autoPlay muted loop id="bg-video" controlsList="nodownload" playsInline>
        <source src="/media/vid14.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    </div>
    
   

    {/* First Section */}
    <div className='home-body1'>
      <h1>{website_data.first_section.heading1}</h1>
      <h2>{website_data.first_section.heading2}</h2>
      <p>{website_data.first_section.core_message.text}</p>
      <button className='contact-button' onClick={scrollToForm}>{website_data.header.contact_us}</button>
    </div>

      </div>
     

      {/* Testimonial Section */}
      <div className='home-reviews'  data-aos="zoom-out"
      data-aos-delay={250}>
      <h3>{website_data.testimonial_section.testimonial_message}</h3>
        <h1>{website_data.testimonial_section.heading}</h1>
        
      </div>

  <div className='gradient2'>
     {/* Second Section */}
     <div className='home-body2'>
    
        <h1>{website_data.second_section.heading}</h1>
        <p>{website_data.second_section.core_message.text}</p>
      </div>

      {/* Services and Benefits Section */}
      <div className='home-services'>
      
  <h1>{website_data.services_and_benefits.heading}</h1>

{/* images */}

  <img src="/media/pic1.svg" alt="Icon 1" className="icon icon-1" />
  <img src="/media/pic3.svg" alt="Icon 3" className="icon icon-3" />
  
 
 


  <div className="benefits-grid">
    {website_data.services_and_benefits.benefits.map((benefit, index) => (
      <div key={index} className="benefit-box" data-aos="fade-up"
      data-aos-delay={index * 100}>
        <h2>{benefit.heading}</h2>
        <p>{benefit.description}</p>
      </div>
    ))}
  </div>
</div>


      {/* Call-to-Action Section with Contact Form */}
      <div className="home-cta" ref={formRef} data-aos="flip-up"
      data-aos-delay={250}>
      <h1>Integrate an AI Chatbot Into Your Website Today!</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            placeholder="Your Name"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            placeholder="Your Email"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone (Country Code + Number)</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            placeholder="Your Phone Number"
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            placeholder="Your Message"
            onChange={handleChange}
          />
        </div>
        <div className="checkbox-container">
        <input
          type="checkbox"
          id="privacy-policy"
          checked={agreeToPolicy}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="privacy-policy" className='privacy-agreement'>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> I agree to the Privacy Policy</a>.
        </label>
      </div>
    <button type="submit">
      {website_data.call_to_action_section.contact_form.submit_button.text}
    </button>
  </form>
</div>


  </div>
     

      {/* Footer Section */}
      <div className='home-footer'>
        <p>{website_data.footer.text}</p>
        <img className='logo_header' alt="Logo" src="/media/logo_white2.png"/>
        <a href={website_data.footer.legal.privacy_policy} className="privacy-footer">Privacy Policy</a>
        <a href="/terms-of-service" className="terms-footer">Terms Of Service</a>
      </div>
      
    </div>
  );
}

export default Home_Page;
